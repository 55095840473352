<template>
  <div class="inline-block w-full sm:w-auto">
    <div
      class="
        flex flex-col
        lg:flex-row
        bg-eiffel
        p-5
        rounded-2xl
        lg:space-x-5
        space-y-12
        lg:space-y-0
      "
    >
      <!-- White box -->
      <div class="bg-snow p-6 py-12 rounded-2xl relative">
        <p class="absolute top-0 mt-7 font-bold text-void text-17 lowercase">
          {{ $t('pages.prices.prices_banner.from') }}
        </p>

        <!-- Top section -->
        <div
          class="
            flex flex-col
            sm:flex-row
            items-center
            justify-between
            sm:space-x-14
          "
        >
          <!-- Month price -->
          <p>
            <span class="font-bold text-40">{{
              price(prices.basic).split(',')[0]
            }}</span
            ><span class="font-bold text-26 text-void"
              >.{{ price(prices.basic).split(',')[1]
              }}{{ $t('pages.prices.prices_banner.month') }}</span
            >
          </p>

          <Label no-margin no-padding no-bg upper large>
            {{ $t('pages.prices.prices_banner.basic.title') }}
          </Label>
        </div>

        <!-- Line -->
        <div class="h-0.5 w-full bg-snowball my-12"></div>

        <!-- Features -->
        <div class="space-y-4 text-left">
          <div
            v-for="(feature, i) in $t(
              'pages.prices.prices_banner.basic.features'
            )"
            :key="i"
            class="flex items-center space-x-5"
          >
            <component
              :is="require('@/assets/icons/check.svg')"
              class="text-eiffel"
              style="min-width: 1.25rem; width: 1.25rem"
            />

            <p class="title-3 text-tire">{{ feature }}</p>
          </div>
        </div>
      </div>

      <!-- Blue box -->
      <div class="px-6 pb-6 flex items-start flex-col">
        <Label color="snow" icon="star" upper class="mx-auto lg:ml-0">
          {{ $t('pages.prices.prices_banner.pro.label.title') }}
        </Label>

        <!-- Top section -->
        <div
          class="
            flex flex-col
            sm:flex-row
            items-center
            justify-between
            sm:space-x-14
            w-full
          "
          style="margin-top: 6px"
        >
          <!-- Month price -->
          <p class="text-snow">
            <template v-if="prices.pro !== 'TBA'">
              <span class="font-bold text-40">{{
                price(prices.pro).split(',')[0]
              }}</span
              ><span class="font-bold text-26 opacity-50"
                >.{{ price(prices.pro).split(',')[1]
                }}{{ $t('pages.prices.prices_banner.month') }}</span
              >
            </template>

            <template v-else>
              <span class="font-bold text-40">
                {{ $t('pages.prices.prices_banner.tba') }}
              </span>
              <span class="font-bold text-26 opacity-50">
                {{ $t('pages.prices.prices_banner.month') }}
              </span>
            </template>
          </p>

          <Label
            no-margin
            no-padding
            no-bg
            upper
            large
            color="snow"
            icon="flame"
          >
            {{ $t('pages.prices.prices_banner.pro.title') }}
          </Label>
        </div>

        <!-- Line -->
        <div class="h-0.5 w-full bg-snow my-12 opacity-70"></div>

        <!-- Features -->
        <div class="space-y-4 text-left">
          <div
            v-for="(feature, i) in $t(
              'pages.prices.prices_banner.pro.features'
            )"
            :key="i"
            class="flex items-center space-x-5"
          >
            <component
              :is="require('@/assets/icons/check.svg')"
              class="text-snow"
              style="min-width: 1.25rem; width: 1.25rem"
            />

            <p class="title-3 text-snow">{{ feature }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from '@/data.json'
import Label from '@/components/Label'

export default {
  name: 'PricesBanner',
  components: {
    Label
  },
  data: () => ({
    prices: data.prices
  }),
  methods: {
    price(price) {
      return this.$n(price, 'currency')
    }
  }
}
</script>
