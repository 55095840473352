<template>
  <div
    class="rounded-full bg-opacity-20 inline-block"
    :class="[
      !noBg ? `bg-${color}` : '',
      {
        uppercase: upper,
        'mb-4': !noMargin
      }
    ]"
  >
    <div
      class="flex items-center space-x-2"
      :class="{
        'px-3 py-1': !noPadding
      }"
    >
      <component
        v-if="icon && icon !== 'dot'"
        :is="require(`@/assets/icons/${icon}.svg`)"
        class="w-4 h-4"
        :class="`text-${color}`"
      />

      <div v-else class="w-2 h-2 rounded-full" :class="`bg-${color}`" />

      <p
        :class="[
          'm-0',
          'font-bold',
          `text-${color}`,
          {
            'text-12': !large,
            'text-16': large
          }
        ]"
      >
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Label',
  props: {
    icon: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: () => 'eiffel'
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: () => false
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: () => false
    },
    noBg: {
      type: Boolean,
      required: false,
      default: () => false
    },
    upper: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
