<template>
  <div
    class="
      page-container
      py-16
      md:py-20
      md:text-center
      md:flex
      items-center
      justify-between
      space-y-10
      md:space-y-0
    "
  >
    <component :is="require('@/assets/icons/polly.svg')" class="w-32 h-full" />
    <p>{{ $t('components.footer.copyright', { year }) }}</p>

    <a
      :href="`mailto:${email}`"
      class="
        block
        text-eiffel
        opacity-80
        hover:opacity-100
        transition
        duration-75
        ease-out
        caption
      "
    >
      {{ email }}
    </a>
  </div>
</template>

<script>
import data from '@/data'

export default {
  name: 'Footer',
  data: () => ({
    email: data.contact.email
  }),
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>
