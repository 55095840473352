<template>
  <div class="fill-screen-mobile bg-ice flex-1 flex-center">
    <div class="page-container">
      <!-- Content -->
      <div class="flex-center flex-col w-full text-center">
        <component :is="require('@/assets/icons/search-3D.svg')" />

        <h1 class="bold-title md:bold-title-lg mt-10">
          {{ $t('pages.404.title') }}
        </h1>

        <i18n path="pages.404.desc" tag="p" class="text-tire body mt-4">
          <template v-slot:link>
            <router-link
              :to="{ name: 'Home' }"
              class="
                text-eiffel
                opacity-80
                hover:opacity-100
                transition
                duration-75
                ease-out
                font-bold
              "
            >
              {{ $t('pages.404.link').toLowerCase() }}
            </router-link>
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped></style>
