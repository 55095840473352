import Vue from 'vue'
import VueI18n from 'vue-i18n'

// App translations
import nl from './translations/nl.json'

Vue.use(VueI18n)

const languages = {
  nl: { ...nl }
}

const numberFormats = {
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  }
}

const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: Object.assign(languages),
  numberFormats
})

export default i18n
