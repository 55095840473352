<template>
  <nav class="navigation page-container flex justify-between items-center h-24">
    <div class="flex items-center space-x-12">
      <!-- Logo -->
      <component
        :is="require('@/assets/icons/polly.svg')"
        class="w-32 h-full"
      />

      <!-- Links -->
      <ul class="hidden md:flex items-center space-x-12">
        <li v-for="(link, i) in links" :key="i">
          <router-link :to="{ name: link.to }">
            <p class="opacity-40 hover:opacity-100 transition ease-in-out">
              {{ link.label }}
            </p>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Start now CTA -->
    <Button
      v-if="$route.name !== 'Prices' && $route.name !== 'NotFound'"
      pill
      upper
      class="hidden md:block"
      @click="$router.push({ name: 'Prices' })"
    >
      {{ $t('components.navigation.cta') }}
    </Button>

    <!-- Mobile menu hamburger icon -->
    <button
      @click="openNavigation"
      class="md:hidden"
      :aria-label="$t('accessibility.open_menu_button')"
    >
      <component
        :is="require('@/assets/icons/menu-icon.svg')"
        class="w-8 text-mine"
      />
    </button>

    <!-- Mobile nav -->
    <div
      class="
        bg-snow
        absolute
        top-0
        bottom-0
        left-0
        right-0
        z-10
        transition
        duration-200
        transform
      "
      :class="{
        '-translate-y-0': open,
        '-translate-y-full': !open
      }"
    >
      <div class="page-container">
        <!-- Top part -->
        <div class="h-24 flex justify-end items-center">
          <button
            @click="closeNavigation"
            :aria-label="$t('accessibility.close_menu_button')"
          >
            <component
              :is="require('@/assets/icons/close.svg')"
              class="w-6 text-mine"
            />
          </button>
        </div>

        <!-- Links -->
        <ul class="space-y-4 mt-6">
          <li v-for="(link, i) in links" :key="i" @click="closeNavigation">
            <router-link :to="{ name: link.to }">
              <p class="body opacity-30 py-2">
                {{ link.label }}
              </p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      body: null,
      open: false,
      links: [
        {
          to: 'Home',
          label: this.$t('components.navigation.links.home')
        },
        {
          to: 'Prices',
          label: this.$t('components.navigation.links.prices')
        }
      ]
    }
  },
  created() {
    this.body = document.querySelector('body')
  },
  methods: {
    openNavigation() {
      this.open = true
      this.body.classList.toggle('overflow-y-hidden')
    },
    closeNavigation() {
      this.open = false
      this.body.classList.toggle('overflow-y-hidden')
    }
  }
}
</script>

<style lang="scss">
nav.navigation {
  a.router-link-exact-active p {
    @apply opacity-100;
  }
}
</style>
