<template>
  <div class="bg-ice">
    <!-- Boost productivity -->
    <div
      class="
        boost
        relative
        py-20
        flex flex-col
        items-end
        md:flex-row
        md:items-start
      "
    >
      <component
        :is="require('@/assets/icons/polly-p-bg.svg')"
        class="absolute top-0 polly-p-bg text-eiffel"
      />

      <div class="page-container page-container--small">
        <div class="max-w-md md:max-w-xs">
          <Label icon="cloud">
            {{ $t('pages.home.boost.label.title') }}
          </Label>

          <div class="mb-8 space-y-3">
            <h1 class="bold-title md:bold-title-lg">
              {{ $t('pages.home.boost.title') }}
            </h1>

            <p class="text-tire body">
              {{ $t('pages.home.boost.desc') }}
            </p>
          </div>

          <Button pill upper @click="$router.push({ name: 'Prices' })">
            {{ $t('pages.home.boost.cta') }}
          </Button>
        </div>
      </div>

      <!-- Planning graphic on mobile devices -->
      <component
        :is="require('@/assets/icons/planning-mobile.svg')"
        class="planning-mobile md:hidden mt-14 pl-4 justify-self-end"
      />

      <!-- Planning graphic on desktop devices -->
      <component
        :is="require('@/assets/icons/planning-desktop.svg')"
        class="hidden planning-desktop md:block absolute right-5"
      />
    </div>

    <!-- Info sections -->
    <div
      class="
        page-container page-container--small
        mt-20
        md:mt-32
        lg:mt-48
        xl:mt-72
        2xl:mt-96
        space-y-20
        md:space-y-32
      "
    >
      <InfoSection
        v-for="(info, i) in infoSections"
        :key="i"
        :graphic="info.graphic"
        :label="info.label"
        :title="info.title"
        :desc="info.desc"
        :reverse="info.reverse"
      />
    </div>

    <!-- Start today banner -->
    <div class="page-container page-container--small mt-32 pb-20">
      <Banner
        :title="$t('pages.home.start_today.title')"
        :desc="$t('pages.home.start_today.desc')"
        :button="$t('pages.home.start_today.cta')"
        desc-icon="lightning"
        @buttonClick="$router.push({ name: 'Prices' })"
      />
    </div>
  </div>
</template>

<script>
import i18n from '@/locales'
import Label from '@/components/Label'
import InfoSection from '@/components/InfoSection'
import Banner from '@/components/Banner'

export default {
  name: 'Home',
  metaInfo: {
    title: i18n.t('pages.home.meta_info.title'),
    meta: [
      { name: 'description', content: i18n.t('pages.home.meta_info.desc') }
    ]
  },
  components: {
    Label,
    InfoSection,
    Banner
  },
  data() {
    return {
      infoSections: [
        {
          graphic: 'optimalisation',
          label: this.$t('pages.home.info_sections.optimalisation.label.title'),
          title: this.$t('pages.home.info_sections.optimalisation.title'),
          desc: this.$t('pages.home.info_sections.optimalisation.desc')
        },
        {
          graphic: 'communication',
          label: this.$t('pages.home.info_sections.communication.label.title'),
          title: this.$t('pages.home.info_sections.communication.title'),
          desc: this.$t('pages.home.info_sections.communication.desc'),
          reverse: true
        },
        {
          graphic: 'manage-data',
          label: this.$t('pages.home.info_sections.manage_data.label.title'),
          title: this.$t('pages.home.info_sections.manage_data.title'),
          desc: this.$t('pages.home.info_sections.manage_data.desc')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.boost {
  svg.polly-p-bg {
    max-width: 600px;
    left: -4rem;

    @media (min-width: 768px) {
      max-width: 80%;
    }

    @media (min-width: 1024px) {
      max-width: 70%;
    }

    @media (min-width: 1536px) {
      max-width: 1075px;
    }
  }

  svg.planning-mobile {
    max-width: 352px;

    @media (min-width: 640px) {
      max-width: 70%;
    }
  }

  svg.planning-desktop {
    max-width: 30%;

    @media (min-width: 768px) {
      max-width: 45%;
      margin-top: 2rem;
    }

    @media (min-width: 1024px) {
      max-width: 50%;
      margin-top: 0;
    }

    @media (min-width: 1280px) {
      max-width: 53%;
      right: 2%;
    }

    @media (min-width: 1536px) {
      max-width: 870px;
      right: 8%;
    }

    @media (min-width: 2000px) {
      max-width: 970px;
      right: 10%;
    }
  }
}
</style>
