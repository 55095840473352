<template>
  <div class="bg-space py-4 md:py-10 px-6 md:px-16 rounded-2xl relative">
    <div class="md:flex items-center justify-between space-y-7 md:space-y-0">
      <div>
        <h2 class="title-1-bold text-snow mb-2">{{ title }}</h2>
        <p class="text-snow body opacity-60">
          {{ desc }}

          <component
            :is="require(`@/assets/icons/${descIcon}.svg`)"
            class="w-5 h-5 inline"
          />
        </p>
      </div>

      <Button
        pill
        upper
        v-if="button"
        @click="$emit('buttonClick')"
        class="z-10 relative"
      >
        {{ button }}
      </Button>
    </div>

    <component
      :is="require('@/assets/icons/polly-p-banner.svg')"
      class="
        absolute
        left-0
        sm:left-auto
        sm:right-5
        top-0
        h-full
        text-snow
        w-full
        sm:w-auto
      "
    />
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    descIcon: {
      type: String,
      required: false,
      default: () => ''
    },
    button: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
