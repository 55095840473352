<template>
  <div class="flex flex-col min-h-screen">
    <InfoBanner class="hidden md:block" />

    <Navigation />

    <router-view></router-view>

    <Footer />
  </div>
</template>

<script>
import InfoBanner from '@/components/InfoBanner'
import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    InfoBanner,
    Navigation,
    Footer
  },
  mounted() {
    this.setAppLanguage()
  },
  watch: {
    '$i18n.locale'() {
      this.setAppLanguage()
    }
  },
  methods: {
    setAppLanguage() {
      const html = document.documentElement
      html.setAttribute('lang', this.$i18n.locale)
    }
  }
}
</script>

<style>
body {
  overflow-y: scroll;
}
</style>
