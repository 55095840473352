<template>
  <div class="bg-eiffel text-snow">
    <div class="page-container flex items-center justify-center h-16">
      <p v-html="$t('components.info_banner')"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBanner'
}
</script>
