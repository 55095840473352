import Vue from 'vue'

// Imports
import Ripple from 'vue-ripple-directive'
import Button from '@/components/Button'
import VTooltip from 'v-tooltip'
import VueMeta from 'vue-meta'

// Options
const tooltipOptions = {
  defaultPlacement: 'bottom',
  defaultClass: 'tooltip'
}

// Directives
Vue.directive('ripple', Ripple)

// Components
Vue.component('Button', Button)

// Plugins
Vue.use(VTooltip, tooltipOptions)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
