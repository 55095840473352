<template>
  <div class="grid md:grid-cols-2 gap-8 md:gap-20 xl:gap-28 grid-flow-row">
    <component
      :is="require(`@/assets/icons/info-sections/${graphic}.svg`)"
      :class="{
        'md:order-2': reverse
      }"
    />

    <div class="flex flex-col items-start xl:mt-10">
      <Label v-if="label">{{ label }}</Label>

      <div class="space-y-3">
        <h2 class="title-1-bold">{{ title }}</h2>
        <p class="text-tire body">{{ desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Label from '@/components/Label'

export default {
  name: 'InfoSection',
  components: {
    Label
  },
  props: {
    graphic: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    title: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    reverse: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
