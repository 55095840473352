var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-full bg-opacity-20 inline-block",class:[
    !_vm.noBg ? ("bg-" + _vm.color) : '',
    {
      uppercase: _vm.upper,
      'mb-4': !_vm.noMargin
    }
  ]},[_c('div',{staticClass:"flex items-center space-x-2",class:{
      'px-3 py-1': !_vm.noPadding
    }},[(_vm.icon && _vm.icon !== 'dot')?_c(require(("@/assets/icons/" + _vm.icon + ".svg")),{tag:"component",staticClass:"w-4 h-4",class:("text-" + _vm.color)}):_c('div',{staticClass:"w-2 h-2 rounded-full",class:("bg-" + _vm.color)}),_c('p',{class:[
        'm-0',
        'font-bold',
        ("text-" + _vm.color),
        {
          'text-12': !_vm.large,
          'text-16': _vm.large
        }
      ]},[_vm._t("default")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }