<template>
  <div class="bg-ice py-24">
    <div class="page-container flex flex-col items-center">
      <Label>
        {{ $t('pages.prices.start_now.label.title') }}
      </Label>

      <div class="mb-20 space-y-3 text-center">
        <h1 class="bold-title md:bold-title-lg md:max-w-xl mx-auto">
          {{ $t('pages.prices.start_now.title') }}
        </h1>

        <p class="text-tire body md:max-w-sm mx-auto">
          {{ $t('pages.prices.start_now.desc') }}
        </p>
      </div>

      <div class="flex flex-col items-center space-y-8">
        <a
          class="
            text-eiffel
            title-1-bold
            opacity-80
            hover:opacity-100
            transition
            duration-100
            ease-out
          "
          :href="`tel:${phone}`"
        >
          {{ phone }}
        </a>

        <a
          class="
            text-eiffel
            title-1-bold
            opacity-80
            hover:opacity-100
            transition
            duration-100
            ease-out
          "
          :href="`mailto:${email}`"
        >
          {{ email }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import data from '@/data'
import Label from '@/components/Label'

export default {
  name: 'StartNowBanner',
  components: {
    Label
  },
  data: () => ({
    phone: data.contact.phone,
    email: data.contact.email
  })
}
</script>
