<template>
  <div class="min-h-screen">
    <div class="page-container py-20 text-center">
      <Label icon="dot">
        {{ $t('pages.prices.easy_prices.label.title') }}
      </Label>

      <div class="mb-20 space-y-3">
        <h1 class="bold-title md:bold-title-lg md:max-w-xl mx-auto">
          {{ $t('pages.prices.easy_prices.title') }}
        </h1>

        <p class="text-tire body md:max-w-sm mx-auto">
          {{ $t('pages.prices.easy_prices.desc') }}
        </p>
      </div>

      <PricesBanner />
    </div>

    <StartNowBanner />
  </div>
</template>

<script>
import i18n from '@/locales'
import Label from '@/components/Label'
import PricesBanner from '@/components/PricesBanner'
import StartNowBanner from '@/components/StartNowBanner'

export default {
  name: 'Prices',
  metaInfo: {
    title: i18n.t('pages.prices.meta_info.title'),
    meta: [
      { name: 'description', content: i18n.t('pages.prices.meta_info.desc') }
    ]
  },
  components: {
    Label,
    PricesBanner,
    StartNowBanner
  }
}
</script>
